import request from "@/utils/request";
/**
 * 获取分类下的视频
 * @returns promise
 */
export const videoTypeList = () => {
  return request("/api/front/videoCategory/list", "get");
};


/**
 * 获取分类下的视频 - 分页
 * @returns promise
 */
export const videoGetpage = (data) => {
  return request("api/front/videoCategory/getpage", "get", data);
};
