<template>
    <div class="videoPlay">
        <video ref="m3u8_video" class="video-js vjs-default-skin vjs-big-play-centered" controls>
            <source :src="path" />
        </video>
    </div>
</template>
<script setup>
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import videojs from 'video.js'
import 'video.js/dist/video-js.css';
const props = defineProps({
    path: {
        type: String,
        required: true
    },
    autoPlay: {
        type: Boolean,
        default: false,
    }
})
const m3u8_video = ref()
let player;
const initPlay = async () => {
    await nextTick()
    const options = {
        muted: true,
        controls: true,
        autoplay: props.autoPlay && props.path,
        loop: true,
        notSupportedMessage: '此视频暂无法播放，请检查视频路径或稍后再试',
        techOrder: ["html5"],
    };
    player = videojs(m3u8_video.value, options, function onPlayerReady() {
        videojs.log('播放器已经准备好了!');
        if (props.autoPlay && props.path) {
            this.play()
        }
        this.on('ended', function () {
            videojs.log('播放结束了!');
        });
        this.on('error', function () {
            videojs.log('播放器解析出错!');
        })
    });
}
onMounted(() => {
    initPlay()
})
//直接改变路径测试
watch(() => props.path, () => {
    player.pause()
    player.src(props.path)
    player.load()
    if (props.path) {
        player.play()
    }
})
onBeforeUnmount(() => {
    player.dispose()
})

</script>
<style lang="less" scoped>
.videoPlay {
    
    width: 100%;
    height: 100%;

    .video-js {
        height: 100%;
        width: 100%;
    }
}
</style>